import React from 'react'
import Link from 'next/link'
import { Box, Container, Grid, Link as MaterialUiLink, Typography } from '@mui/material/'
import { Theme } from '@mui/material/styles'
import { StyledTextA } from '@components/atoms/StyledTextA'

const Footer = () => (
  <Box sx={{ bgcolor: (theme: Theme) => theme.palette.grey[200], overflow: 'hidden', pt: 2 }}>
    <StyledTextA>
      <Container>
        <Grid container={true} spacing={5}>
          <Grid item xs={12} sm={12} md={3}>
            <Grid container={true} direction="column" justifyContent="flex-start" spacing={2}>
              <Grid item={true}>
                <img
                  style={{ width: '120px' }}
                  src="https://chematels.s3-ap-northeast-1.amazonaws.com/logo.png"
                  alt="Chematels"
                />
              </Grid>
              <Grid item={true}>
                <Typography color="textSecondary" variant="caption">
                  {`© ${new Date().getFullYear()} Chematels`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item={true} xs={12} sm={12} md={3}>
            <Typography variant="h6" gutterBottom={true}>
              Chematelsについて
            </Typography>
            <Grid container={true} direction="column">
              <Link href="/about/terms">
                <a>
                  <Typography
                    gutterBottom={true}
                    sx={{
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    利用規約
                  </Typography>
                </a>
              </Link>
              <Typography gutterBottom={true}>
                <MaterialUiLink
                  color="textPrimary"
                  href="https://www.kanamorisangyo.co.jp/privacy"
                  underline="hover"
                >
                  プライバシーポリシー
                </MaterialUiLink>
              </Typography>
              <Link href="/about/contact">
                <a>
                  <Typography
                    gutterBottom={true}
                    sx={{
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    お問い合わせ
                  </Typography>
                </a>
              </Link>
              <Link href="/about/faq">
                <a>
                  <Typography
                    gutterBottom={true}
                    sx={{
                      '&:hover': {
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    よくあるご質問
                  </Typography>
                </a>
              </Link>
              <Typography gutterBottom={true}>
                <MaterialUiLink
                  color="textPrimary"
                  href="https://www.kanamorisangyo.co.jp/company/outline"
                  underline="hover"
                >
                  運営会社
                </MaterialUiLink>
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Typography variant="h6" gutterBottom={true}>
              関連するサービス
            </Typography>
            <Grid container={true} direction="column">
              <Typography gutterBottom={true}>
                <MaterialUiLink color="textPrimary" href="https://plabase.com/" underline="hover">
                  プラスチック成形材料DB｜PlaBase
                </MaterialUiLink>
              </Typography>
              <Typography gutterBottom={true}>
                <MaterialUiLink
                  color="textPrimary"
                  href="https://www.plaquick.com/"
                  underline="hover"
                >
                  試作開発｜PlaQuick
                </MaterialUiLink>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </StyledTextA>
  </Box>
)

export default Footer
