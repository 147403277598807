import React from 'react'
import Router, { useRouter } from 'next/router'
import { Box, BottomNavigation, BottomNavigationAction, Hidden } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode'
import FolderIcon from '@mui/icons-material/Folder'
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt'

function Navigation() {
  const router = useRouter()

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    Router.push(newValue)
  }

  return (
    <Hidden mdUp={true}>
      <Box sx={{ mt: 8 }} />
      <BottomNavigation
        value={router.pathname}
        onChange={handleChange}
        showLabels={true}
        sx={{
          flexGrow: 1,
          width: '100vw',
          position: 'fixed',
          bottom: 0,
          backgroundColor: '#fafafa',
          borderTop: '1px solid #9e9e9e',
          borderBottom: '1px solid #9e9e9e',
        }}
      >
        <BottomNavigationAction icon={<ChromeReaderModeIcon />} label="ニュース" value="/" />
        <BottomNavigationAction icon={<SignalCellularAltIcon />} label="市況情報" value="/market" />
        <BottomNavigationAction icon={<FolderIcon />} label="カタログ" value="/catalogs" />
        <BottomNavigationAction icon={<AccountCircleIcon />} label="My" value="/my" />
      </BottomNavigation>
    </Hidden>
  )
}

export default Navigation
