import styled from 'styled-components'

export const StyledTextA = styled.div`
   {
    a {
      color: black;
      text-decoration: none;
    }
  }
`
